export const environment = {
    production: false,

    BASE_URL: "https://dev.api.team.wginb.com",
    LOGIN_ENDPOINT: "/wgteamlogin/login",
    LOGIN_SSO_ENDPOINT: "/wgteamlogin/ssologin",

    STATIC: '/wgteam/static',
    USER_DETAILS: '/wgteam/member/{email}',
    USER_PROFILE: '/wgteam/member/{email}/profile',

    EMPLOYEES: {
        ORGANIZATION_SUMMARY_ENDPOINT: "/wgteam/summary",
        MEMBER_LIST_ENDPOINT: "/wgteam/member/list",
        ADD_MEMBER_ENDPOINT: "/wgteam/member",
        UPDATE_MEMBER_ENDPOINT: "/wgteam/member/{email}",
        DELETE_MEMBER_ENDPOINT: "/wgteam/member/{email}",
    },

    TIME_OFF: {
        SUMMARY: '/wgteam/member/{email}/timeoff/summary',
        AUDIT: '/wgteam/member/{email}/timeoff/audit',
        MEMBER_PENDING_APPROVAL: '/wgteam/member/{email}/timeoff/member/pending/approval/list',
        CANCEL: '/wgteam/member/{email}/timeoff/{timeOffId}',
        APPLY_TIME_OFF: '/wgteam/member/{email}/timeoff',
        HISTORY: '/wgteam/member/{email}/timeoff/history',
        CREDIT:'/wgteam/member/{email}/timeoff/credit'
    },

    APPROVALS: {
        MANAGER_PENDING_APPROVAL: '/wgteam/member/{email}/timeoff/manager/pending/approval/list',
        HISTORY: '/wgteam/member/{email}/timeoff/manager/approval/list',
        ACTION: '/wgteam/member/{email}/timeoff/{timeOffId}/approval/action',
    },

    ATTENDANCE: {
        UPLOAD: '/wgteam/attendance',
        MONTHLY: '/wgteam/attendance/{year}/{month}'
    },

    RESET_PASSWORD: "https://dev.portal.reset.wginb.com",

    EVENTS: {
        LIST_EVENTS: '/wgteam/event/list',
        GET_EVENT_SCORES: '/wgteam/event/{eventId}/scores',
        ADD_EVENT: '/wgteam/event',
        ADD_TEAM: '/wgteam/event/{eventId}/team',
        ADD_ACTIVITY: '/wgteam/event/{eventId}/activity',
        ADD_SCORE: '/wgteam/event/{eventId}/team/{teamId}/activity/{activityId}/score'
    },

    MSAL_CONFIG: {
        clientId: "2e52c79e-026c-4a11-b3d3-4ca7fb2830ad",
        authority: "https://login.microsoftonline.com/ac313092-082d-44a3-9637-6a2e02b2a8ad",
        redirectUri: "https://dev.portal.team.wginb.com",
        postLogoutRedirectUri: 'https://dev.portal.team.wginb.com'
    }
};
