import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AuthService,
  StaticSessionStorageKey,
  TokenSessionStorageKey,
  UserSessionStorageKey
} from '../shared/services/auth.service';
import { Router } from '@angular/router';
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { LoginResponse } from '../shared/models/external/login-response.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });
  loginFormSubmitted = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  resetPasswordUrl: string = environment.RESET_PASSWORD;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {};

  ngOnInit() {
    if(this.authService.isUserLoggedIn$) {
      this.router.navigate(['/home']);
    }
  }

  onSubmit(){
    if(this.loginForm.valid) {
      this.loginFormSubmitted = true;
      this.authService.login({
        username: this.loginForm.value['username']!,
        password: this.loginForm.value['password']!
      })
      .subscribe({
        next: () => {
          this.authService.isUserLoggedIn$.next(true);
          this.router.navigate(['/home'])
        },
        error: () => {
          this.loginFormSubmitted = false;
        }
      });
    }
  }
}
